import React, { Component } from "react";



class FAQs extends Component {
  render() {

    return (
      <div>
        {/* <ul className="social">
                <a href="/" className="button btn project-btn">
                  <i className="fa fa-arrow-left"></i>Back
                </a>
                
              </ul> */}
        <div id="faqs">
          <h1>FAQs</h1>

          <p>Click a question to be taken to the answer.</p>

          <ul><h3><a href="#what">What is Refresh Connections?</a></h3></ul>
          <ul><a href="#name">Why did you choose the name Refresh Connections?</a></ul>
          <ul><a href="#how-covid">How does Covid inform Refresh?</a></ul>
          <ul><a href="#terms">Where are your terms and privacy policy?</a></ul>

          <ul><a href="#who-refresh">Who is Refresh Connections for?</a></ul>
          <ul><a href="#not">Who is Refresh Connections NOT for?</a></ul>
          <ul><a href="#online-inperson">Is Refresh Connections for meeting online or in-person friends?</a></ul>
          <ul><a href="#who-find">Who can I expect to find on Refresh?</a></ul>
          <ul><a href="#vision">What kind of vision do you have for Refresh Connections?</a></ul>
          <ul><a href="#conscientious">What do you mean by being Covid Conscientious?</a></ul>


          <ul><h3><a href="#use-app">How do I use the Refresh Connections app?</a></h3></ul>
          <ul><a href="#meet">How do I meet new friends on Refresh Connections?</a></ul>
          <ul><a href="#choice">How do you make choices from potential friends?</a></ul>
          <ul><a href="#bug">Why did you choose a bug icon?</a></ul>
          <ul><a href="#profile">What is your profile?</a></ul>
          <ul><a href="#required">What info is required on your profile?</a></ul>
          <ul><a href="#break">What's the difference between pausing, deactivating, and deleting your account?</a></ul>
          <ul><a href="#pro">What features does the Pro version come with?</a></ul>
          <ul><a href="#attachments">Can I send attachments in Chats?</a></ul>
          <ul><a href="#attachments-gray">Why are the attachment options grayed out for some people?</a></ul>
          <ul><a href="#attachments-settings">What if I don't want to receive attachments</a></ul>
          <ul><a href="#attachments-expire">How long do attachments last in my Chats?</a></ul>
          <ul><a href="#attachments-longer">Can I keep my attachments in Chats for longer?</a></ul>
          <ul><a href="#remove-chat">Can I unsend a message in Chats?</a></ul>


          <ul><a href="#softlaunch">Will Refresh have an initial &#8220;soft launch&#8221; enrollment period?</a></ul>

          <ul><h3><a href="#refreshments">What is the Refreshments Bar?</a></h3></ul>
          <ul><a href="#refreshments-name">Why is it called the Refreshments Bar?</a></ul>
          <ul><a href="#participate">How do you participate in the community?</a></ul>
          <ul><a href="#post">What can I post in the Refreshments Bar?</a></ul>
          <ul><a href="#comment">What can I comment on a Refreshments Bar post?</a></ul>
          <ul><a href="#remove-comment">Can I remove a comment I left on a Refreshments Bar post?</a></ul>
          <ul><a href="#sidenote">What does it mean for a comment to be sidenoted?</a></ul>
          <ul><a href="#category">How do I choose which category my post should go in?</a></ul>
          <ul><a href="#username">What name does the Refreshments community see on Refresh Connections?</a></ul>


          <h2 id="what">What is Refresh Connections?</h2>

          <p>Refresh Connections is a new place where you can connect, make friends, and join a community with people who are conscientious about Covid. People come here to:</p>

          <ul>
            <li> meet</li>
            <li> get together</li>
            <li> mingle</li>
            <li> support each other</li>
            <li> share stories and tips and tricks</li>
            <li> learn the latest about Covid politics and science</li>
            <li> plan events</li>
            <li> create change</li>
          </ul>

          <h2 id="name">Why did you choose the name Refresh Connections?</h2>
          <p>We&#8217;ve yet to meet anyone who is Covid Conscientious that hasn&#8217;t had a winnowing of relationships during the pandemic as old friends and partners throw caution to the wind and expose themselves (and everybody else, including you) to repeat infections.</p>
          <p>We created Refresh Connections as a place where people could refresh their friendships and other connections with Covid Conscientious ones. So welcome, and start refreshing!</p>

          <h2 id="how-covid">How does Covid inform Refresh Connections?</h2>
          <p>Covid is the driving force behind Refresh Connections.</p>
          <p>We have been shocked by the ableism and lack of care that led to the abandonment of the precautionary principle as Covid continues to cause ruin worldwide.</p>
          <p>We all have been left scrambling, trying to come together online in all the usual Big Tech places—even as they actively suppress and discourage us from gathering and finding information.</p>
          <p>Now we have a safe and welcoming place: Refresh Connections.</p>

          <h2 id="who-refresh">Who is Refresh Connections for?</h2>
          <p>Refresh Connections is designed for Covid Conscientious people looking for a safe and welcoming place, including:</p>

          <ul>
            <li> people who have never had Covid and don&#8217;t want to get it</li>
            <li> people who have had Covid and don&#8217;t want to get it again</li>
            <li> people who have Long Covid</li>
            <li> people who have family and friends with Long Covid</li>
            <li> people who have lost family and friends from Covid and Long Covid</li>
            <li> scientists and doctors wanting to share information</li>
          </ul>

          <h2 id="not">Who is Refresh Connections NOT for?</h2>


          <ul>
            <li><p> <strong>The not sure and the not ready:</strong> If you aren&#8217;t sure that you are worried about getting or spreading Covid, then this isn&#8217;t your place. If you aren&#8217;t ready to stop getting and spreading Covid, the same applies. Why? Because our first goal is to make a safe and effective place for the Covid Conscientious. That is our mission.</p></li>
            <li><p> <strong>The minimizers:</strong> Think it&#8217;s cool to downplay or deny everything Covid has done, continues to do, and what it might do? Don&#8217;t come to Refresh Connections.</p></li>
            <li><p> <strong>The shamers:</strong> If you want to shame, make fun of, or call people crazy for worrying about the Covid? All the other services welcome you. Don&#8217;t come here.</p></li>
            <li><p> <strong>The trolls:</strong> People (or bots) who volunteer or get paid to disrupt and downgrade conversations about Covid aren&#8217;t welcome. And will be banned.</p></li>
          </ul>

          <p>Please report minimizers, shamers, and trolls. We will ban them.</p>

          <h2 id="online-inperson">Is Refresh Connections for meeting online or in-person friends?</h2>
          <p>Both.</p>
          <p>Refresh Connections provides the opportunity to do either—or both!</p>

          <h2 id="who-find">Who can I expect to find on Refresh Connections?</h2>
          <p>People join Refresh Connections for different reasons.</p>
          <p>Some are just looking to make friends: online, in-person, or both. Some are looking to be more than friends.</p>
          <p>Others have a more directed goal, like finding a roommate who&#8217;s Covid Conscientious. Or offering a job to someone who is Covid Conscientious.</p>
          <p>Some are people impacted by Long Covid—or the people who care for them—who join to find support and information.</p>
          <p>Other people might join to share and learn at our community forum, the Refreshments Bar.</p>
          <p>Our community is filled with many different types of people, all with the same purpose: to grow in how they are Covid Conscientious.</p>
          
          <h2>Can people come here to look for roommates?</h2>
          <p>Yes</p>

          <h2>Can people come here to offer housing?</h2>
          <p>Yes</p>

          <h2>Can people come here to look for jobs?</h2>
          <p>Yes</p>

          <h2>Can people come here to offer jobs?</h2>
          <p>Yes</p>

          <h2 id="terms">Where are your terms and privacy policy?</h2>
          <p>All of our documentation is available on the front page of our website <a href="https://refreshconnections.com">refreshconnections.com</a> and linked to when you sign up for an account. They are also available from our app store listing. 
          Here are the direct links to our <a href="https://refreshconnections.com/terms">Terms</a> and <a href="https://refreshconnections.com/privacy">Privacy Policy</a>. We also encourage you to check out our <a href="https://refreshconnections.com/principles">Member Principles</a> and <a href="https://refreshconnections.com/commmunitysafety">Community Safety guidelines</a>.</p>


          <h2 id="vision">What kind of vision do you have for Refresh Connections?</h2>
          <p>See the next question about what makes a Covid Conscientious individual.</p>


          <h2 id="conscientious">What do you mean by being Covid Conscientious?</h2>
          <p>We are all here to meet Covid Conscientious people and connect with other people who care. After all, can you really be conscientious without being Covid Conscientious. With that in mind:</p>
          <h3>Covid Conscientious people make friends with other Covid Conscientious people</h3>
          <p>We created Refresh Connections to help you do this. So go to Picks and get started!</p>

          <h3>Covid Conscientious people keep learning</h3>
          <p>Covid Conscientious people are engaged. They keep up with and follow the science, which means they wear respirators when they can. They stay up to date on vaccines.</p>
          <h3>Covid Conscientious people live transparently</h3>
          <p>Covid Conscientious people are transparent and know how to communicate their risk status. Here are two scenarios that imply different levels of safety. A Covid Conscientious person knows the difference, and tells their connections before meeting in person.</p>

          <ul>
            <li> I have been masking at work and in public places and I have no symptoms.</li>
            <li> I&#8217;ve isolated by myself from people for 5 days and have taken 3 negative lateral flow tests every other day.</li>
          </ul>

          <h3>Covid Conscientious people understand that each person controls their own health and safety</h3>
          <p>It&#8217;s your health and your safety.</p>
          <p>We all expected the government and big business to guide and protect us through the pandemic. But they didn&#8217;t. And they turned Public Health into a free for all.</p>
          <p>So where does that leave the Covid Conscientious person? Protecting their health and their safety.</p>
          <p>A Covid Conscientious person sees minimization and the shaming of safety for what it is, a power play by someone who doesn&#8217;t understand what is yours and what is theirs. That&#8217;s true if it&#8217;s a family member, an old friend, a boss or a bigger organization. Don&#8217;t give them your health or your safety.</p>
          <h3>Covid Conscientious people understand risk (or want to!)</h3>
          <p>We all see risk differently. One reason for that is we all engage with risk with different levels of worry and watchfulness. What separates the Covid Conscientious is they grapple with it. They understand that being Covid Conscientious requires the energy and commitment to worry and watch. So they commit the energy and they find friends who help.</p>
          <p>Look to the Refreshments Bar for ongoing discussions about risk. See you there!</p>

          <h3>Covid Conscientious people seek to be part of a Lasting, Safe Place for other Covid Conscientious People</h3>
          <p>Covid Conscientious people endeavor to support lasting, safe places for each other. We need sustaining places to first survive then thrive. We see that as our work here and we see it as an urgency. We don&#8217;t see ourselves as the only place to try to accomplish this and are absolutely supportive of similar efforts. The world is too diverse and Covid is too big a threat not to see many options spring up. Hopefully we can all work together as this pandemic continues and other pandemics loom.</p>


          <h2 id="use-app">How do I use the Refresh Connections app?</h2>
          <p>Read on!</p>

          <h2 id="meet">How do you meet new friends on Refresh Connections?</h2>
          <p>You can meet new friends in several different ways.</p>
          <p>In Picks, you browse through profiles of other Refreshers and Like the ones that you are interested in connecting with. Tap the blue heart on the bottom right side to send them a Like, or hit the Bug icon to ignore them.</p>
          <p>In Likes, you browse through the people who are interested in connecting with you. Like them back if you would like to connect!</p>
          <p>In Chats, you can take part in conversations with people who have shared your interest to connect. Friends can also connect in groups of three or more here in the Group Chats section.</p>
          <p>In Community, you can discuss the topics opened at our Refreshments Bar, where everyone in the community can participate. Members with the setting "Connect at Refreshments" toggled on can also view profiles and send Likes from the Refreshments Bar community forum.</p>

          <h2 id="choice">How do you make choices from potential friends?</h2>
          <p>First, as you browse through your choices in Picks and Likes you can look at each person's profile and find out a lot about them, such as their reasons for coming to Refresh, the types of friends they want to meet, where they are located, etc.</p>
          <p>You can also change your filter settings as you browse to increase or decrease the numbers of people you are looking through, for example, by setting the geographical area or the age range of your choices. You can also filter based on if you are looking for online or in person friends, or both!</p>
          <p>More about filters in our <a href='/tips#filters'>How Tos</a>.</p>

          <h2 id="bug">Why did you choose a bug icon?</h2>
          <p>If you find something in a profile that "bugs" you, that&#8217;s probably not the best initial choice for a friend. So you click the bug and that potential connection goes away for a bit.</p>
          <p>You might see them again in the Picks section, or sometimes they will wind up picking you. In that case it&#8217;s a second chance at a pair, which we like to think of as a bug potentially turning into a butterfly!</p>
          <p>In any case, it&#8217;s just a bit of fun as you do something that should be fun: making new friends!</p>

          <h2>What happens to a choice you use the bug icon on?</h2>
          <p>You won&#8217;t see them as a choice in Picks for a bit.</p>
          <p>There are also options to block and report this member.</p>

          <h2>What do you do if somebody Likes you?</h2>
          <p>If somebody Likes you and you Like them back, then the next step is to go to Chats and start a conversation with them. After all, you are here to make friends.</p>
          <p>Wonder how to start a conversations? Check out their profile for any number of interests and things they like to talk about!</p>
          <p>If somebody Likes you and you click the bug button "Ignore for now" option, then you won&#8217;t see them for a bit and, of course, they won&#8217;t show up as a connection in your Chats.</p>
          <h2 id="profile">What is your profile?</h2>
          <p>Your profile is your introduction to potential friends. It has things like name, where you live, some pictures, etc. It gives you a chance to describe who you are and why you're here. </p>
          <p>It also includes a list of conversation starters you might like to talk about.</p>
          <p>Potential friends will review your profile as they decide who to reach out to, just like you will do when looking at theirs!</p>

          <h2 id="required">What info is required on your profile?</h2>
          <p>When you create a profile on Refresh Connections, you are beginning the process of making friends. Friends need to know information about one another—they need to know the real you. It's also a matter of keeping our members safe. This is why we require your profile to have basic information about you.</p>
          <p>Your profile should include your name, age, approximate location, what kinds of connections you are looking for, and at least one picture of your face.</p>
          <p>Don't feel like sharing this information? You can pause your profile. You can still participate in the Refreshments Bar community, but you can't connect 1-1 with others, so you will not show up in their Picks.</p>
          <p>In short, if you want to see other people's profiles, you need to have a real profile.</p>

          <h2>What name do you reveal to potential friends on Refresh?</h2>
          <p>Your preferred name, which you choose as the name that is revealed on Refresh.</p>
          <p>This is the name that shows up on your profile, and the expectation is that it is the name you use as your first name with your friends and family in real life.</p>
          <p>You can also choose a name to use at the Refreshments Bar. This name also shows up on your profile, which means that people with access to your profile will be able to link it to your preferred name.</p>
          
          <h2>Why can&#8217;t you change your location more than every sixty days?</h2>
          <p>We want this to be the real you using Refresh Connections, and changing locations frequently is a red flag for misuse of the service.</p>
          <p>Contact us at <a href="mailto:help@refreshconnections">help@refreshconnections.com</a> if you have legitimate needs that require you to change your location more than every sixty days.</p>
          
          <h2 id="break">What's the difference between pausing, deactivating, and deleting your account?</h2>
          <h3>Pausing your profile</h3>
          <p>Sometimes you need a break from making new friends, and that's okay! When you pause your account, you can continue your conversations with connections you have already made. These connections can also still view your profile. However, you will not show up in Picks and Likes, and the setting to connect at the Refreshments Bar will be turned off. You can unpause your profile at any time.</p>
          <p>In short, no new one-on-one connections will be made.</p>
          <p>This option is also good if you do not feel like creating a Profile yet and just want to participate in the Refreshments Bar community discussion.</p>

          <h3>Deactivating your profile</h3>
          <p>When you deactivate your account, you will not appear in Picks and Likes, you cannot receive messages, and users you have already connected with will see "Deactivated User" when they try to view your profile. You can reactivate your profile at any time.</p>
          <p>If you need a longer break from the app but at some point might come back, this is what we recommend. Other users won't be able to see your profile and you won't get any private messages while you are deactivated.</p>

          <h3>Deleting your account</h3>
          <p>Deleting your account is a permanent option. To keep our community safe, we do not allow people to delete and re-make their accounts - this is to stop people from causing disruption in the community, deleting, then coming back on using their same email and phone number. Members who are banned also cannot sign up.</p>
          <p>When you delete your account, your profile is deleted. Connections you have made can still see messages you have sent them, just like if you delete an email off your computer, it does not delete the email from another person's computer. They will see your profile as "Deactivated User".</p>
          <p>If you have deleted your account in error, you can contact support at <a href="mailto:help@refreshconnections">help@refreshconnections.com</a>. Please note, information may be irretrievable as delineated in our data deletion policies.</p>

          <h2 id="softlaunch">Will Refresh Connections have an initial &#8220;soft launch&#8221; enrollment period?</h2>
          <p>Absolutely.</p>
          <p>The first people to join Refresh Connections will join during our soft launch, a period where some of our features won&#8217;t yet be activated or might not work like they will once we reach a sufficient number for our formal launch. For example, during the soft launch, we will keep the filter clear that determines who you are shown under your Picks tab as potential friends.</p>
          <p>Once we start to grow, we will activate more filters and you can search for friends based on your goals, such as people in your neighborhood wanting in-person friendship, or Covid Conscientious people offering housing.</p>
          <p>Our inaugural members can play a key role as we continue to develop the site&#8217;s features during the soft launch.</p>
          <p>We will keep those who join early updated frequently on all the new features and changes coming to the app.</p>
          <p>The Refresh Connections team looks forward to meeting you!</p>

          <h2 id="pro">What features does the Pro version come with?</h2>
          <p>Refresh Connections is a free community - all users have the ability to make an unlimited number of connections, participate in unlimited one-on-one and group chats with members of the community, and take part in all the conversations at the Refreshments Bar. We have made every effort to design Refresh Connections to be fully functioning for everybody that wants to participate, no matter their situation.</p>
          <p>Refresh Connections Pro is a subscription, which unlocks advanced features.</p>

          <ul>
            <li> Special Pro banner with several new options coming soon.</li>
            <li> View all your Likes at once, without making a decision, as opposed to evaluating one-by-one.</li>
            <li> See all conversational prompts on others' Profiles.</li>
            <li> Attachments in Chats last longer.</li>
            <li> Toggle off filter reminder in Picks and Likes.</li>
            <li> Start conversations by submitting posts at the Refreshments Bar.</li>
            <li> Use Initiate mode to only be viewed by people whose Profile you like first. </li>
            <li> Filter your Picks by keyword. For example, find people who mentioned "anime" somewhere in their Profile.</li>
          </ul>

          <h2 id="attachments">Can I send attachments in Chats?</h2>
          <p>Yes! Anyone can now send images and audio messages. Click the paperclip icon to the left of the message box for options.</p>

          <h2 id="attachments-gray">Why are the attachment options grayed out for some people?</h2>
          <p>You might be trying to send an attachment to a member who hasn't updated to the latest version of the app or who has chosen to not allow attachments to be sent to them.</p>

          <h2 id="attachments-settings">What if I don't want to receive attachments?</h2>
          <p>You can toggle off receiving images, audio messages, or both in your Me tab &gt; Settings under Communication Settings.</p>

          <h2 id="attachments-expire">How long do attachments last in my Chats?</h2>
          <p>Images remain available for 7 days after they were sent. Audio messages remain available for 3 days after they were sent.</p>

          <h2 id="attachments-longer">Can I keep my attachments in Chats for longer?</h2>
          <p>Yes. If you choose a Pro Subscription, images remain available for 6 months and audio messages remain available for 2 weeks.</p>

          <h2 id="remove-chat">Can I unsend a message in Chats?</h2>
          <p>You can unsend your message up to 2 minutes after you sent it — just swipe from right to left to reveal the unsend button. Once your message has been unsent, a note will show as you having unsent a message. </p>
          <p>If your messaging partner is on their phone, they might see your message until you are able to remove it. It is still best practices to double check your message before sending it.</p>
          
          <h2 id="refreshments">What is the Refreshments Bar?</h2>
          <p>The Refreshments Bar is our Community Forum, where everyone on Refresh can talk and mingle. It's a space that brings together our broad and inclusive group of people to make friends and create positive change. </p>
          <p>Pick a Refreshments Bar name, choose a topic, and leave a response. Or sit back and read. It&#8217;s all good.</p>
          <p>We are all Covid Conscientious here, so let's keep the space safe by reporting anyone who is minimizing, shaming, or trolling.</p>

          <h2 id="refreshments-name">Why is it called the Refreshments Bar?</h2>
          <p>We're Refresh Connections, these discussion posts are like announcements, hence the portmanteau "Refreshments". We thought it fitting since getting refreshments is a good time to mingle. Plus it's cute, no?</p>

          <h2 id="post">What can I post in the Refreshments Bar?</h2>

          <p>The conversations we have at the Refreshments Bar should reflect the purpose of our community to connect our diverse group of Covid Conscientious individuals and create positive change, and the following guidelines are designed to support this purpose. We also provide moderation to facilitate the thoughtful spread of information to our community and prevent disruption through argumentative and overly negative engagement.  </p>

          <ul>
            <li> All posts are subject to our editorial review, which is final. </li>
            <li> All posts are also subject to our Terms and Conditions. </li>
            <li> During our soft launch, we cannot initially publish every post. We plan to accommodate all posts that pass editorial review by the end of the soft launch.</li>
            <li> No repetitive posts - we will encourage you to respond in a comment instead to make sure there is space for all conversations. </li>
            <li> We will respond to most posts within 3 business days by direct communication with you. Posts approved first-pass will be posted without further need for communication. </li>
            <li> We make small edits where necessary, but will communicate directly with you if more significant editing or revision is necessary.  </li>
            <li> Suggest tags, but understand the editorial team will make final decisions about appropriate use of tags. </li>
            <li> Use standard grammar and spelling. Colloquialisms can be effective as occasional conversation starters in the title, but not in the body of the post. </li>
            <li> Use conversational tone unless the topic (ie, science) dictates otherwise. Be aware that humor can create confusion in print, and is more often subject to editorial revision. </li>
            <li> Posts must be original. Share your story. </li>
            <li> Posts should be topical to the purpose of our community: making Covid Conscientious connections and creating positive change</li>
            <li> Posts must be less than 1000 characters. Titles must be less than 40 characters. </li>
            <li> Please post in the version of standard English you are most comfortable with (ie, British, American) </li>
            <li> Images are optional, but should be original or submitted with license verification that they are free to use for any purpose. </li>
            <li> Images are subject to editorial review and must be in keeping with the purpose of the Refreshments Bar. </li>
            <li> Posts must be positive and welcoming to everyone. </li>
            <li> The editorial team may suggest that some posts be initially worked out in small group discussion with friends. </li>
            <li> Posts cannot contain personal information such as phone numbers, emails, or addresses. </li>
            <li> No advertising your own business, product, or organization.</li>
            <li> Posts are not for bugs or other customer support issues. Contact <a href="mailto:help@refreshconnections.com"> help@refreshconnections.com</a> for those needs.</li>
            <li> No requests to connect individually. This is what Picks and your Profile are for! You are welcome to turn on your "Connect from Refreshments" setting and allow users to send you Likes because of your post.</li>
            <li> Stay active in the comments of your post. </li>
            <li> Posters can sidenote specific comments without notifying the commenter. </li>

          </ul>

          <h2 id="comment">What can I comment on a Refreshments Bar post?</h2>

          <p> We are a diverse and inclusive community. Respect all members in your choice of words. </p>
          <ul>
            <li> No personal attacks. </li>
            <li> No personal information such as phone numbers, emails, or addresses. </li>
            <li> Comments or questions should be concise for greatest engagement.  </li>
            <li> Comments should be relevant to the post to maintain focus for all members of the community. </li>
            <li> Please read everyone's comments before commenting to be sure you aren't repeating. If you like another person's post, then use the like heart. </li>
            <li> Avoid sensitive and inflammatory topics. We are here to connect and create positive change for the Covid Conscientious community, not battle over diversionary and disruptive topics. Be "pro" identity but no hate speech. </li>
            <li> No Covid minimizing. </li>
            <li> No disruption, either directly or via passive aggression.</li>
            <li> Use the report button (revealed by swiping left on a comment) to report inappropriate content or behavior on the forum. </li>
            <li> Don't be negative. Where you disagree, share your experience or provide what you see as a solution. That's how the community grows! </li>
            <li> Do not post spam.</li>
            <li> Do not advertise your own business, product, or organization. </li>
            <li> Focus on the facts to find common ground.</li>
            <li> Use "I" statements so as not to assign blame and keep defensiveness at bay. </li>
            <li>The author of the original post can sidenote any comment.</li>
            <li> Moderation solutions include asking members to take the conversation into a private one-on-one or small group chat, comment deletion, warnings, time-outs, suspensions, and account deletion.</li>
          </ul>

          <h2 id="remove-comment">Can I remove a comment I left on a Refreshments Bar Post?</h2>

          <p>You can remove your comment up to 2 minutes after you posted — just swipe from right to left to reveal the remove message button (on light mode, it's the black button of the message with an X on it on the very right).</p>
          
          <div style={{textAlign:"center"}}>
          <img
                style={{maxWidth: "300px"}}
                src="images/remove-comment.png"
                alt="Screenshot of a comment after swiping right to left to reveal more buttons"
              />
            <p style={{fontSize:"14pt"}}>Swipe from right to left to reveal the Remove Message button.</p>
          </div>

          <p>Your comment will show up as "This comment has been removed" and be immediately sidenoted. Please note, your comment might be seen before you are able to remove it. It is still best practices to double check your comment before posting it.</p>
          <p>If you have other reasons to remove a comment that has been posted for more than two minutes, please reach out to <a href="mailto:help@refreshconnections.com"> help@refreshconnections.com</a>.</p>


          <h2 id="sidenote">What does it mean for a comment to be sidenoted?</h2>
          <p>A side-noted comment is one that is moved by the moderator or author of the post because it is off-topic but not in violation of community guidelines.  These comments remain, but those reading the post will have to click an extra button to see them, knowing that they have been moved because they break the flow of the conversation.  </p>
          <p>For example, say there's a post asking for tips on how to stay Covid Conscientious while traveling to a non-Covid Conscientious sister's wedding. A comment like "you shouldn't go because your sister isn't taking your best interest into account" will be sidenoted. We will assume the original post was made by someone who has considered the pros and cons and is now trying to make their situation as safe as possible.</p>
          <p>If you see a comment that should be sidenoted, you can let the moderators know by swiping right to left on the comment and hitting the circle with the negative sign.</p>
          <p>We have also seen people make off-topic posts that on their own would make great starting posts. If that’s the case, we might bother you about turning the comment into a separate post.</p>

          <h2 id="category">How do I choose which category my post should go in?</h2>
          <p>Here's what each Bar was designed for.</p>
          <ul>
            <li><strong>The Mingle Bar</strong>: Anything strictly social belongs here! If it doesn’t fit into another category, it probably belongs here too. </li>
            <li><strong>The Long Covid Bar</strong>: Everything Long Covid, from prevention to diagnosis to treatment to support. </li>
            <li><strong>The Families Bar</strong>: Connect with other families on unique issues about navigating family issues from pregnancy through adult children taking care of their elderly parents. </li>
            <li><strong>The Pop Bar</strong>:  Another canceled concert? Let’s talk about it. </li>
            <li><strong>The STEAM Bar</strong>: Science, Technology, Engineering, Arts, and Math. We foresee a lot of chat about articles here, and maybe even some surprise guests! We think big here at Refresh Connections. </li>
            <li><strong>The Change Bar</strong>: We gather here to make a difference, with posts about how community can create change, both for our community and for the Greater Movement. We think even bigger here.</li>
            <li><strong>The Refreshments Bar</strong>: This will be posts from the Refresh Connections team regarding updates, tips for using the app, checklists for newcomers, etc. </li>
          </ul>
          <p>Still don't know? We're happy to choose for you.</p>

          <h2 id="username">What name does the Refreshments community see on Refresh Connections?</h2>
          <p>The community sees the username you have chosen for the Refreshments Bar. If you have your "Connect from Refreshments" setting turned on (it is off by default), other members will also be able to see your Profile.</p>

          
        </div>
      </div>
    )
  }
}

export default FAQs;


